@tailwind base;
@tailwind components;
@tailwind utilities;
.spiner{
  width:100%;
  margin-top: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
